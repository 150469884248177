import React from 'react'

import styles from '../styles/FooterStyle.module.css'

const Footer = () => {
  return (
    <div class={styles.foot}>
    <p>Samplications©</p>
      <p>Website created maintained by <a href="www.samplications.com">Samplications</a></p>
    </div>
  )
}

export default Footer
